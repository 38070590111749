import { ComponentType, useEffect, useRef } from "react"
import { MotionValue, useAnimation } from "framer-motion"

function getRandomPosition(
    minDistance: number = 20,
    maxDistance: number = 40
): { x: number; y: number } {
    const angle = Math.random() * 2 * Math.PI
    const distance = Math.random() * (maxDistance - minDistance) + minDistance

    return {
        x: Math.cos(angle) * distance,
        y: Math.sin(angle) * distance,
    }
}

function getRandomDelay(min: number = 500, max: number = 1500): number {
    return Math.random() * (max - min) + min
}

export function withRandomCursorMovement(
    Component: ComponentType
): ComponentType {
    return (props) => {
        const controls = useAnimation()
        const lastPosition = useRef({ x: 0, y: 0 })
        const instanceId = useRef(Math.random())

        const waitForDelay = (min: number, max: number) =>
            new Promise((resolve) =>
                setTimeout(resolve, getRandomDelay(min, max))
            )

        useEffect(() => {
            const moveRandomly = async () => {
                await waitForDelay(0, 3000) // Initial random delay

                while (true) {
                    let newPosition = getRandomPosition()
                    const threshold = 5 // Distance threshold for position change

                    // Check the distance and regenerate the position if necessary
                    while (
                        Math.abs(newPosition.x - lastPosition.current.x) <
                            threshold &&
                        Math.abs(newPosition.y - lastPosition.current.y) <
                            threshold
                    ) {
                        newPosition = getRandomPosition()
                    }

                    lastPosition.current = newPosition

                    await controls.start({
                        x: newPosition.x,
                        y: newPosition.y,
                        transition: {
                            duration: 2,
                            type: "spring",
                            stiffness: 50,
                            damping: 10,
                        },
                    })

                    await waitForDelay(500, 1500) // Random delay between movements
                }
            }

            moveRandomly()

            return () => {
                controls.stop()
            }
        }, [controls])

        return <Component {...props} animate={controls} />
    }
}
